var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/quiz/s1/bgImage.jpg"),"alt":""}}),_c('span',{staticClass:"bgScreen"}),_c('div',{staticClass:"content"},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.subtitle)}}),_c('a',{attrs:{"href":"https://www.cyber.gov.au/acsc/view-all-content/essential-eight/essential-eight-explained"}},[_vm._v(_vm._s(_vm.paraLink))]),_c('p',{staticClass:"subtitle2",domProps:{"innerHTML":_vm._s(_vm.subtitle2)}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"Name","mode":"eager","rules":"required|min:3|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isStarted)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.full_name),expression:"full_name"}],attrs:{"type":"text","placeholder":"Your full name"},domProps:{"value":(_vm.full_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.full_name=$event.target.value}}}):_vm._e(),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isStarted)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","placeholder":"Your email address"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}):_vm._e(),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Company name","mode":"eager","rules":"required|min:3|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isStarted)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company_name),expression:"company_name"}],attrs:{"type":"text","placeholder":"The name of your company"},domProps:{"value":(_vm.company_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company_name=$event.target.value}}}):_vm._e(),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(!_vm.isStarted)?_c('button',{staticClass:"cta-btn",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.button2)+" ")]):_vm._e()],1)]}}])}),(_vm.isStarted)?_c('button',{staticClass:"cta-btn",on:{"click":function($event){_vm.isStarted = false}}},[_vm._v(" "+_vm._s(_vm.button)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }