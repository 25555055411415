<template>
  <div>
    <span class="bg"></span>
    <img class="dots" src="@/assets/quiz/quiz1/dots.svg" alt />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bg {
  display: block;
  width: 100%;
  height: 40%;
  min-height: 200px;
  background: linear-gradient(142.44deg, #bb0e1d 9.04%, #7e1518 221.13%),
    rgba(187, 14, 29, 0.21);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.dots {
  position: fixed;
  top: 30%;
  right: 10px;
  z-index: -1;
}
</style>