<template>
  <div class="container">
    <img src="@/assets/quiz/s1/bgImage.jpg" alt />
    <span class="bgScreen"></span>
    <div class="content">
      <h1>{{ title }}</h1>
      <p v-html="subtitle"></p>
      <a
        href="https://www.cyber.gov.au/acsc/view-all-content/essential-eight/essential-eight-explained"
        >{{ paraLink }}</a
      >
      <p class="subtitle2" v-html="subtitle2"></p>

      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            name="Name"
            mode="eager"
            rules="required|min:3|alpha_spaces"
            v-slot="{ errors }"
            ><input
              v-if="!isStarted"
              type="text"
              v-model="full_name"
              placeholder="Your full name"
            /><span>{{ errors[0] }}</span></ValidationProvider
          >
          <ValidationProvider
            name="email"
            mode="eager"
            rules="required|email"
            v-slot="{ errors }"
          >
            <input
              v-if="!isStarted"
              type="email"
              v-model="email"
              placeholder="Your email address"
            />
            <span>{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Company name"
            mode="eager"
            rules="required|min:3|alpha_spaces"
            v-slot="{ errors }"
            ><input
              v-if="!isStarted"
              type="text"
              v-model="company_name"
              placeholder="The name of your company"
            /><span>{{ errors[0] }}</span></ValidationProvider
          >
          <button v-if="!isStarted" class="cta-btn" type="submit">
            {{ button2 }}
          </button>
        </form>
      </ValidationObserver>

      <button v-if="isStarted" @click="isStarted = false" class="cta-btn">
        {{ button }}
      </button>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
// import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

// No message specified.
extend("email", email);

// Override the default message.
extend("required", {
  ...required,
  message: "This field is required",
});
export default {
  data() {
    return {
      isStarted: true,
      title: "Let's Get Started!",
      subtitle:
        "Keeping your computers secure can seem like a daunting task. Sometimes it can be hard to know “how well you are doing”. <br/> <br/> We’ve built this short quiz to help you assess the strength of your business IT security. <br/> At the end, we’ll give you a score that helps you understand the strength of your IT security, loosely modelled on the Australian Government’s ",
      paraLink: "Essential 8 steps to cyber security.",
      subtitle2:
        "<br/> And one last thing, we care about your privacy. Even though we're collecting some basic information about who completes this quiz, we won’t save the individual answers to your questions unless you give us permission, so your data is safe!",
      button: "Get Started",
      button2: "Continue",
      fullNameVal: "",
      emailVal: "",
      companyNameVal: "",
    };
  },
  computed: {
    full_name: {
      get() {
        return this.$store.state.UserDetails.full_name
      },
      set(value) {
        return this.$store.commit('UserDetails/setFullName', value)
      }
    },
    email: {
      get() {
        return this.$store.state.UserDetails.email
      },
      set(value) {
        return this.$store.commit('UserDetails/setEmail', value)
      }
    },
    company_name: {
      get() {
        return this.$store.state.UserDetails.company_name
      },
      set(value) {
        return this.$store.commit('UserDetails/setCompanyName', value)
      }
    }
  },
  methods: {
    updateValue: function(value) {
      this.$emit("input", value);
    },
    onSubmit() {
      this.$emit("btn-click", true);
      this.$store.dispatch('UserDetails/submit');
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    setTimeout(() => {
      console.log("Mounted the view");
      window.scrollTo(0, 0);
    })
  },
};
</script>

<style lang="scss" scoped>
@mixin lato-base {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  color: #777777;
}
span {
  display: block;
}
.container {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  @include lato-base();
  color: #fff;
  text-align: left;

  .content {
    margin: 0 auto;
    max-width: 85vw;
    padding-top: 25vh;
    padding-bottom: 5vh;
    min-height: 120vh;
  }

  h1 {
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 24px;
  }
  p {
    display: inline;
    font-size: 16px;
    line-height: 22px;
  }
  a {
    text-decoration: none;
    color: crimson;
  }
  .subtitle2 {
    display: block;
  }
  img {
    width: 100vw;
    min-height: 110%;
    object-fit: cover;
    position: absolute;
    z-index: -2;
    top: -10%;
    left: 0;
  }

  input {
    display: block;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    // opacity: 0.6;
    padding: 9px 0.3rem;
    margin: 18px 0;
    font-size: 1rem;
    width: 80%;
    color: white;

    &:focus {
      border-bottom: 1px solid rgba(255, 255, 255, 1);
      // opacity: 1;
    }
    &::placeholder {
      color: white;
      opacity: 0.6;
    }
  }
  .bgScreen {
    width: 100vw;
    min-height: 110%;
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background: #001226;
    opacity: 0.8;
  }

  .cta-btn {
    margin: 0 auto;
    margin-top: 25px;
    padding: 11px 40px;
    border: none;
    outline: none;
    background: #aa121c;
    border-radius: 8px;
    cursor: pointer;
    transition: 200ms all ease-out;
    // border: 1px solid rgba(255, 255, 255, 0.28);

    // opacity: 0.8;
    &:hover {
      opacity: 1;
      background: #bb0e1d;
    }

    @include lato-base();
    font-size: 18px;
    line-height: 134.69%;
    color: #ffffff;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    .content {
      max-width: 35vw;
      min-height: 1250px;
      padding-top: 35vh;
    }
    input {
      width: 60%;
    }
  }
}
</style>
