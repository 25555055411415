<template>
  <div>
    <h1>Redirects to original site</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
div {
  padding-top: 30vh;
}
</style>
