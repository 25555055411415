<template>
  <div class="quiz">
    <section1
      v-show="!quizStart"
      @btn-click="quizStartMethod"
      class="section1"
    />

    <!-- @email="user.email=$event"
      @companyName="user.companyName=$event"
      :emailVal="user.email"
    :companyNameVal="user.companyName"-->

    <quizTemplate
      :qTemplate="quiz1Template"
      v-if="quizStart && !quiz1Complete"
      @btn-click="quiz1Method"
    />
    <quizTemplate
      :qTemplate="quiz2Template"
      v-if="quizStart && quiz1Complete && !quiz2Complete && quiz1Answers.ans1"
      @btn-click="quiz2Method"
      @roundScore="totalScore = totalScore + $event"
      @roundQuestions="totalQuestions = totalQuestions + $event"
    />
    <quizTemplate
      :qTemplate="quiz3Template"
      v-if="quizStart && quiz1Complete && !quiz3Complete && !quiz1Answers.ans1"
      @btn-click="quiz3Method"
      @roundScore="totalScore = totalScore + $event"
      @roundQuestions="totalQuestions = totalQuestions + $event"
    />

    <quizTemplate
      :qTemplate="quiz4Template"
      v-if="quizStart && (quiz2Complete || quiz3Complete) && !quiz4Complete"
      @btn-click="quiz4Method"
      @roundScore="totalScore = totalScore + $event"
      @roundQuestions="totalQuestions = totalQuestions + $event"
    />
    <quizTemplate
      :qTemplate="quiz5Template"
      v-if="quizStart && quiz4Complete && !quiz5Complete"
      @btn-click="quiz5Method"
      @roundScore="totalScore = totalScore + $event"
      @roundQuestions="totalQuestions = totalQuestions + $event"
    />
    <quizTemplate
      :qTemplate="quiz6Template"
      v-if="quizStart && quiz5Complete && !quiz6Complete"
      @btn-click="quiz6Method"
      @roundScore="totalScore = totalScore + $event"
      @roundQuestions="totalQuestions = totalQuestions + $event"
    />
    <quizTemplate
      :qTemplate="quiz7Template"
      v-if="quizStart && quiz6Complete && !quiz7Complete"
      @btn-click="quiz7Method"
      @roundScore="totalScore = totalScore + $event"
      @roundQuestions="totalQuestions = totalQuestions + $event"
    />
    <quizTemplate
      :qTemplate="quiz8Template"
      v-if="quizStart && quiz7Complete && !quiz8Complete"
      @btn-click="quiz8Method"
      @roundScore="totalScore = totalScore + $event"
      @roundQuestions="totalQuestions = totalQuestions + $event"
    />
    <!-- <quiz1 v-if="quizStart && !quiz1Complete" @btn-click="quiz1Method" />
    <quiz2
      v-if="quizStart && quiz1Complete && !quiz2Complete && quiz1Answers.ans1"
      @btn-click="quiz2Method"
    />

    <quiz3
      v-if="
        quizStart && quiz1Complete && !quiz3Complete && !quiz1Answers.ans1
      "
      @btn-click="quiz3Method"
    />

    <quiz4
      v-if="quizStart && (quiz2Complete || quiz3Complete) && !quiz4Complete"
      @btn-click="quiz4Method"
    />

    <quiz5 v-if="quizStart && quiz4Complete && !quiz5Complete" @btn-click="quiz5Method" />

    <quiz6 v-if="quizStart && quiz5Complete && !quiz6Complete" @btn-click="quiz6Method" />

    <quiz7 v-if="quizStart && quiz6Complete && !quiz7Complete" @btn-click="quiz7Method" />

    <quiz8 v-if="quizStart && quiz7Complete && !quiz8Complete" @btn-click="quiz8Method" />-->

    <endScreen
      v-if="quizEnd"
      :totalScore="totalScore"
      :totalQuestions="totalQuestions"
    />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import section1 from "@/components/quiz/s1";
import quizTemplate from "@/components/quiz/quizTemplate";
// import quiz1 from "@/components/quiz/quiz1";
// import quiz2 from "@/components/quiz/quiz2";
// import quiz3 from "@/components/quiz/quiz3";
// import quiz4 from "@/components/quiz/quiz4";
// import quiz5 from "@/components/quiz/quiz5";
// import quiz6 from "@/components/quiz/quiz6";
// import quiz7 from "@/components/quiz/quiz7";
// import quiz8 from "@/components/quiz/quiz8";
import endScreen from "@/components/quiz/endScreen";
export default {
  name: "Quiz",
  created() {
    // this.$ksvuescr.$emit("destroy");
  },
  beforeMount() {
    // this.$scrollTo(".section1");
  },
  data() {
    return {
      totalScore: 0,
      totalQuestions: 0,
      quizStart: false,
      quizEnd: false,
      quiz1Complete: false,
      quiz2Complete: false,
      quiz3Complete: false,
      quiz4Complete: false,
      quiz5Complete: false,
      quiz6Complete: false,
      quiz7Complete: false,
      quiz8Complete: false,
      user: {
        email: "",
        fullName: "",
        companyName: "",
      },
      quiz1Template: {
        title: "Medical Hardware Quiz",
        subtitle:
          "To kick us off, we need to know a bit about how your practice uses technology.",
        required: "* Required",
        button: "Next",
        cards: [
          {
            id: 1,
            type: "radio",
            question: "Does your practice use a server?",
            choices: [
              { value: 1, name: "Yes", score: 0 },
              { value: 2, name: "No", score: 0 },
            ],
          },
        ],
      },
      quiz2Template: {
        title: "Your Server or NAS",
        subtitle:
          "That’s great. We’re now going to ask some questions about the server or NAS in your business.",
        required: "* Required",
        button: "Next",
        cards: [
          {
            id: 1,
            type: "radio",
            question: "How old is your server or NAS?",
            choices: [
              { value: 1, name: "Less than 3 years old", score: 4 },
              { value: 2, name: "3 - 5 years old", score: 3 },
              { value: 3, name: "6 - 10 years old", score: 2 },
              { value: 4, name: "Don’t know", score: 1 },
            ],
            answer: Number,
            // answer: [false, false, false, false]
          },
          {
            id: 2,
            type: "radio",
            question:
              "When was the last time you know someone actively installed updates on the server or NAS?",
            choices: [
              { value: 1, name: "Within the last week", score: 4 },
              { value: 2, name: "Within the last month", score: 3 },
              { value: 3, name: "Within the last year", score: 2 },
              { value: 4, name: "Not sure/longer than a year", score: 1 },
            ],
            answer: Number,
          },
          {
            id: 3,
            type: "radio",
            question: "What do you need to remotely access your server or NAS?",
            choices: [
              {
                value: 1,
                name: "I need a username, password and multi-factor token",
                score: 4,
              },
              {
                value: 2,
                name: "I need a username and password and to be on a VPN",
                score: 3,
              },
              { value: 3, name: "I need a username and password", score: 2 },
              { value: 4, name: "I don’t remotely access it", score: 1 },
            ],
            answer: Number,
          },
          {
            id: 4,
            type: "radio",
            question:
              "When was the last time you had an external vulnerability scan or penetration test done?",
            choices: [
              { value: 1, name: "Within the last month", score: 4 },
              { value: 2, name: "Within the last year", score: 3 },
              { value: 3, name: "Within the last 3 years", score: 2 },
              { value: 4, name: "I don’t know/have never done one", score: 1 },
            ],
            answer: Number,
          },
        ],
      },
      quiz3Template: {
        title: "Online Services and The Cloud",
        subtitle:
          "The cloud is becoming a big part of the way we do business. From interacting with government agencies through myGov or the ATO Business Portal, through to the way we do our accounting, or even access patient data; the cloud is now an every-day part of our lives.",
        required: "* Required",
        button: "Next",
        cards: [
          {
            id: 1,
            type: "radio",
            question: "How do you access your cloud services?",
            choices: [
              {
                value: 1,
                name:
                  "All of my cloud services need a username, password and second factor authentication",
                score: 4,
              },
              {
                value: 2,
                name:
                  "Most of my cloud services need a username, password and second factor authentication, but some don’t ",
                score: 3,
              },
              {
                value: 3,
                name:
                  "Some of my cloud services need a username, password and second factor authentication, but most don’t",
                score: 2,
              },
              {
                value: 4,
                name: "I only use a username and password",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 2,
            type: "radio",
            question: "Do you share your cloud logins with anyone else?",
            choices: [
              {
                value: 1,
                name:
                  "All users in our business have their own login credentials for every service",
                score: 4,
              },
              {
                value: 2,
                name:
                  "Most of the services we use have unique logins, but we share for some",
                score: 3,
              },
              {
                value: 3,
                name:
                  "We have unique logins for some services, but we share most of them",
                score: 2,
              },
              {
                value: 4,
                name: "We use the same username and password for everyone",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 3,
            type: "radio",
            question: "How confident are you that your cloud data is secured?",
            choices: [
              {
                value: 1,
                name:
                  "We have reviewed all of our cloud services, their terms of use and understand where and how our data is stored",
                score: 4,
              },
              {
                value: 2,
                name:
                  "We have reviewed most of our services, and trust the cloud providers to keep our data safe.",
                score: 3,
              },
              {
                value: 3,
                name: "We have reviewed some of our services ",
                score: 2,
              },
              {
                value: 4,
                name:
                  "We do not know where our data is stored, or how it is being kept secure",
                score: 1,
              },
            ],
            answer: Number,
          },
        ],
      },

      quiz4Template: {
        title: "Passwords & Access Security",
        subtitle:
          "Thanks for those answers! The next two questions are about how you use passwords and store them.",
        required: "* Required",
        button: "Next",
        cards: [
          {
            id: 1,
            type: "radio",
            question: "How do you manage your passwords?",
            choices: [
              {
                value: 1,
                name:
                  "I use a unique random password for every service, and have a password manager remember them",
                score: 4,
              },
              {
                value: 2,
                name:
                  "I use a unique password for every service, and I remember them myself",
                score: 3,
              },
              {
                value: 3,
                name:
                  "I use unique passwords for some services, but not for all",
                score: 2,
              },
              {
                value: 4,
                name: "I use the same password for most of my services",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 2,
            type: "radio",
            question: "How do you share passwords when you need to?",
            choices: [
              {
                value: 1,
                name: "I use a secure password sharing system",
                score: 4,
              },
              {
                value: 2,
                name: "I send them via a secure method (encrypted message)",
                score: 3,
              },
              {
                value: 3,
                name: "I send them via SMS",
                score: 2,
              },
              {
                value: 4,
                name: "I send them via e-mail or write them down",
                score: 1,
              },
            ],
            answer: Number,
          },
        ],
      },
      quiz5Template: {
        title: "Email and phishing",
        subtitle:
          "In April this year, Google said they block nearly 18 million malware and phishing emails every day. Phishing and email fraud cost businesses billions of dollars each year globally. These questions ask some questions about how you protect against these threats.",
        required: "* Required",
        button: "Next",
        cards: [
          {
            id: 1,
            type: "radio",
            question: "Have you ever received a malicious email?",
            choices: [
              {
                value: 1,
                name:
                  "I know I have received a malicious email, but my computer system alerted me",
                score: 4,
              },
              {
                value: 2,
                name:
                  "I know I have received a malicious email, but I detected it myself",
                score: 3,
              },
              {
                value: 3,
                name:
                  "I think I have received a malicious email, but I’m not sure",
                score: 2,
              },
              {
                value: 4,
                name: "I have never received a malicious email",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 2,
            type: "checkbox",
            question:
              "Is your email scanned for viruses, Trojans and other nasties. If so, how?",
            choices: [
              {
                value: 1,
                name: "We have desktop antivirus",
                score: 1,
              },
              {
                value: 2,
                name: "Our email system scans for viruses",
                score: 1,
              },
              {
                value: 3,
                name: "We use a third party virus scanning system",
                score: 1,
              },
              {
                value: 4,
                name: "None/Don’t know",
                score: 0,
              },
            ],
            answer: [false, false, false, false],
          },
          {
            id: 3,
            type: "checkbox",
            question:
              "How do you prevent against phishing and other email threats?",
            choices: [
              {
                value: 1,
                name:
                  "We have regular training on how to identify phishing emails",
                score: 1,
              },
              {
                value: 2,
                name:
                  "We have automated systems that detect phishing emails and block them",
                score: 1,
              },
              {
                value: 3,
                name:
                  "We have alerts on our emails that tell us when they come from an untrusted source",
                score: 1,
              },
              {
                value: 4,
                name:
                  "I review the URLs and Links of every message before I click on them",
                score: 1,
              },
              {
                value: 5,
                name: "None of the above ",
                score: 0,
              },
            ],
            answer: [false, false, false, false],
          },
          {
            id: 4,
            type: "radio",
            question:
              "How do you share confidential information with third parties?",
            choices: [
              {
                value: 1,
                name: "I distribute it using a secure file sharing service",
                score: 4,
              },
              {
                value: 2,
                name: "I have a secure system where I can upload the content",
                score: 4,
              },
              {
                value: 3,
                name: "I encrypt the information and send it via e-mail",
                score: 4,
              },
              {
                value: 4,
                name: "I just attach it to an email message",
                score: 1,
              },
            ],
            answer: Number,
          },
        ],
      },
      quiz6Template: {
        title: "Software Updates and Anti-Virus",
        subtitle:
          "These questions ask some information about how frequently you update your software, how you protect your data using backups and how you protect your systems from malicious software.",
        required: "* Required",
        button: "Next",
        cards: [
          {
            id: 1,
            type: "radio",
            question:
              "What sort of anti-virus and anti-malware software do you run?",
            choices: [
              {
                value: 1,
                name:
                  "We run anti-virus and anti-malware software on every computer and server",
                score: 4,
              },
              {
                value: 2,
                name: "We run anti-virus on each computer and server",
                score: 3,
              },
              {
                value: 3,
                name: "We run anti-virus on a subset of our computers",
                score: 2,
              },
              {
                value: 4,
                name: "I don’t know",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 2,
            type: "radio",
            question:
              "How often are your computer systems updated for “critical” security issues?",
            choices: [
              {
                value: 1,
                name:
                  "We update our systems within 24 hours of any critical vulnerability",
                score: 4,
              },
              {
                value: 2,
                name:
                  "We update our systems regularly, but not necessarily within a set time frame",
                score: 3,
              },
              {
                value: 3,
                name: "Our systems update themselves",
                score: 2,
              },
              {
                value: 4,
                name: "I don’t run updates, or don’t know how often we update",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 3,
            type: "radio",
            question: "How do you back up your data?",
            choices: [
              {
                value: 1,
                name:
                  "We back up all our data and email to both an on-site and off-site location",
                score: 4,
              },
              {
                value: 2,
                name:
                  "We back up our documents and files on-site and off-site, but use a cloud service for our email",
                score: 3,
              },
              {
                value: 3,
                name: "We back up all or a subset of our data on-site only",
                score: 2,
              },
              {
                value: 4,
                name: "We do not have a regular backup plan",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 4,
            type: "radio",
            question: "How often do you update your software?",
            choices: [
              {
                value: 1,
                name:
                  "We update our software regularly, and have a defined plan to make sure we are using a current, supported version of all applications",
                score: 4,
              },
              {
                value: 2,
                name:
                  "We update our software regularly as we realise we need to do it",
                score: 3,
              },
              {
                value: 3,
                name:
                  "We update some of our software, but not all of our software",
                score: 2,
              },
              {
                value: 4,
                name:
                  "We don’t have an update plan, or I don’t know what it is",
                score: 1,
              },
            ],
            answer: Number,
          },
        ],
      },
      quiz7Template: {
        title: "Desktops, laptops and mobile devices",
        subtitle:
          "We’re nearly at the end; so we’re now going to ask you some questions about the computers and devices you use every day.",
        required: "* Required",
        button: "Next",
        cards: [
          {
            id: 1,
            type: "radio",
            question: "What Operating System are you running?",
            choices: [
              {
                value: 1,
                name: "Windows 7 or earlier",
                score: 0,
              },
              {
                value: 2,
                name: "Windows 8 or Windows 10 or MacOS Mojave or Catalina",
                score: 2,
              },
              {
                value: 3,
                name: "MacOS (older than Mojave or Catalina)",
                score: 0,
              },
              {
                value: 4,
                name: "Mobile devices (iOS or Android)",
                score: 2,
              },
            ],
            answer: Number,
          },
          {
            id: 2,
            type: "radio",
            question:
              "If someone stole your laptop or computer, could they access your data?",
            choices: [
              {
                value: 1,
                name:
                  "We use encryption technology on each device with a unique password",
                score: 4,
              },
              {
                value: 2,
                name:
                  "We use encryption technology on each device but have a common password",
                score: 3,
              },
              {
                value: 3,
                name:
                  "We use usernames and passwords for our computers to protect them",
                score: 2,
              },
              {
                value: 4,
                name: "We do not have any protections on our computer systems",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 3,
            type: "radio",
            question: "Who can install software on your computer?",
            choices: [
              {
                value: 1,
                name:
                  "Our IT person can install software after it has been tested and verified",
                score: 4,
              },
              {
                value: 2,
                name:
                  "Our IT person can install software, but there is no process to test and verify",
                score: 3,
              },
              {
                value: 3,
                name: "A few people in our organisation can install software",
                score: 2,
              },
              {
                value: 4,
                name: "Anyone can install any software they need",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 4,
            type: "radio",
            question: "How often do you replace your computers?",
            choices: [
              {
                value: 1,
                name: "Less than 3 years old",
                score: 4,
              },
              {
                value: 2,
                name: "3 - 5 years old",
                score: 3,
              },
              {
                value: 3,
                name: "6 - 10 years old",
                score: 2,
              },
              {
                value: 4,
                name: "Don’t know",
                score: 1,
              },
            ],
            answer: Number,
          },
        ],
      },
      quiz8Template: {
        title: "Your network and internet connection",
        subtitle:
          "We’re in the final section! The last place we want to ask you about is your network and internet connection.",
        required: "* Required",
        button: "Next",
        cards: [
          {
            id: 1,
            type: "radio",
            question: "Do you have a firewall protecting your office network?",
            choices: [
              {
                value: 1,
                name: "We have a dedicated firewall",
                score: 4,
              },
              {
                value: 2,
                name: "We have a router that also works as a firewall",
                score: 3,
              },
              {
                value: 3,
                name: "We have a modem, but don’t know about a firewall",
                score: 2,
              },
              {
                value: 4,
                name: "We don’t have a firewall/I don’t know",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 2,
            type: "radio",
            question:
              "When was the last time your firewall had it’s software upgraded?",
            choices: [
              {
                value: 1,
                name: "We upgrade our firewall software and hardware regularly",
                score: 4,
              },
              {
                value: 2,
                name: "We upgrade our firewall software regularly",
                score: 3,
              },
              {
                value: 3,
                name: "We upgrade occasionally, or when we feel we need to",
                score: 2,
              },
              {
                value: 4,
                name: "We don’t have a plan to upgrade or do not",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 3,
            type: "radio",
            question:
              "Would you know if your firewall was still supported, or had reached end-of life/security updates?",
            choices: [
              {
                value: 1,
                name:
                  "Yes, we regularly check to make sure our hardware is still supported",
                score: 4,
              },
              {
                value: 2,
                name: "Yes, we recently replaced the device",
                score: 3,
              },
              {
                value: 3,
                name: "We occasionally review this",
                score: 2,
              },
              {
                value: 4,
                name: "We wouldn’t know or don’t have a plan to",
                score: 1,
              },
            ],
            answer: Number,
          },
          {
            id: 4,
            type: "radio",
            question:
              "Have you experienced any performance issues or dropouts on your network?",
            choices: [
              {
                value: 1,
                name: "No, we don’t experience any dropouts",
                score: 4,
              },
              {
                value: 2,
                name:
                  "Yes, we occasionally have dropouts, but we know they are related to our ISP",
                score: 3,
              },
              {
                value: 3,
                name:
                  "Yes, we have regular internet or network dropouts, but they are significant",
                score: 2,
              },
              {
                value: 4,
                name: "We always have dropouts and we don’t know the cause",
                score: 1,
              },
            ],
            answer: Number,
          },
        ],
      },

      quiz1Answers: Object,
      quiz2Answers: Object,
      quiz3Answers: Object,
      quiz4Answers: Object,
      quiz5Answers: Object,
      quiz6Answers: Object,
      quiz7Answers: Object,
      quiz8Answers: Object,
    };
  },
  components: {
    section1,
    quizTemplate,
    // quiz1,
    // quiz2,
    // quiz3,
    // quiz4,
    // quiz5,
    // quiz6,
    // quiz7,
    // quiz8,
    endScreen,
  },
  mounted() {
    setTimeout(() => {
      console.log("Mounted the view");
      window.scrollTo(0, 0);
    });
  },
  methods: {
    quizStartMethod(e) {
      this.quizStart = e;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    quizTemplateMethod(e) {},
    quiz1Method(e) {
      this.quiz1Complete = true;
      this.quiz1Answers = e;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    quiz2Method(e) {
      this.quiz2Complete = true;
      this.quiz2Answers = e;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    quiz3Method(e) {
      this.quiz3Complete = true;
      this.quiz3Answers = e;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    quiz4Method(e) {
      this.quiz4Complete = true;
      this.quiz4Answers = e;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    quiz5Method(e) {
      this.quiz5Complete = true;
      this.quiz5Answers = e;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    quiz6Method(e) {
      this.quiz6Complete = true;
      this.quiz6Answers = e;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    quiz7Method(e) {
      this.quiz7Complete = true;
      this.quiz7Answers = e;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    quiz8Method(e) {
      this.quiz8Complete = true;
      this.quiz8Answers = e;
      this.quizEnd = true;
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped></style>
