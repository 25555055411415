<template>
  <div class="section-container">
    <div class="text-wrapper">
      <h1>Thank you for completing the quiz!</h1>
      <p class="sub" v-html="calculatedOutcome.title"></p>
      <p v-html="calculatedOutcome.subtitle"></p>
      <button class="cta-btn" @click="notify()">Get in touch</button>
    </div>
    <!-- modal -->

    <transition name="fade">
      <div class="modal" v-if="showModal">
        <div class="text-wrapper">
          <h2>We'll be in touch!</h2>
          <p>
            Thanks for completing the survey, we'll get in touch with you over
            the next few business days.
          </p>
          <router-link
            exact
            to="/"
            tag="button"
            class="cta-btn"
            @click="showModal = false"
            >Okay</router-link
          >
        </div>
      </div>
    </transition>

    <transition name="fade">
      <span v-if="showModal" class="bg-screen"></span>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    totalScore: {
      type: Number,
      default: 0,
    },
    totalQuestions: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    calculatedOutcome() {
      let score = Math.round(this.totalScore / this.totalQuestions);
      this.$store.dispatch("UserDetails/sendScore", {
        score: score,
        total_score: this.totalScore,
        total_questions: this.totalQuestions,
      });
      switch (score) {
        case 1:
          return this.outcomes[3];
          break;
        case 2:
          return this.outcomes[2];
          break;
        case 3:
          return this.outcomes[1];
          break;
        case 4:
          return this.outcomes[0];
          break;

        default:
          return null;
          break;
      }
    },
  },
  methods: {
    notify() {
      this.$store.dispatch("UserDetails/notify");
      this.showModal = true;
    },
  },
  data() {
    return {
      showModal: false,
      outcomes: [
        {
          id: 1,
          title:
            "Congratulations! Your <strong> security score <strong> is <strong> Great! <strong>",
          subtitle:
            "This means that your organisation has fantastic security and IT controls in place! <br/> <br/> If you’d like to discuss any of your answers in more detail, or want to look at how our team can enhance your solution click below, and we’ll be in touch!",
        },
        {
          id: 2,
          title:
            "Congratulations! Your <strong>  security score <strong> is <strong> Good! <strong> ",
          subtitle:
            "It looks like you’ve got many of the basics right, but still have some room for improvement. <br/> <br/>  We’d love to chat to you about your answers in more detail. If you’d like us to contact you about this, please click below and we’ll be in touch!",
        },
        {
          id: 3,
          title: "Your <strong>security score<strong> is <strong>OK!<strong>",
          subtitle:
            "It looks like you have an awareness of cyber security and how it impacts your business, but there are definitely some areas for improvement. <br/> <br/> We’d love to chat to you about your answers in more detail and work with you to improve this. If you’d like us to contact you about this, please click below and we’ll be in touch!",
        },
        {
          id: 4,
          title: "Your <strong>security score needs improvement!<strong> ",
          subtitle:
            "It looks like your IT security and technology could use some improvement, and this could benefit from urgent attention.<br/> <br/> We’d love to chat to you about your answers in more detail and work with you to get a quick path to improving this. If you’d like us to contact you about this, please click below and we’ll be in touch!",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin lato-base {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
}
.section-container {
  .fade-enter-active {
    transition: all 0.25s ease-out;
  }
  .fade-leave-active {
    transition: all 0.25s ease-in;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25vh;
  padding-bottom: 20vh;
  // max-width: 80vw;
  // margin: 0 auto;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  // color: #fff;
  text-align: left;
  @include lato-base();
}

.text-wrapper {
  max-width: 30vw;
}
h1 {
  line-height: initial;
  margin-bottom: 30px;
  max-width: 30vw;
}
h3 {
  margin: 10px 0;
  max-width: 30vw;
}
.sub {
  font-size: 20px;
}
p {
  margin: 20px 0;
  max-width: 30vw;
}
.cta-btn {
  margin: 20px 0;
  display: flex;
  padding: 11px 40px;
  border: none;
  outline: none;
  background: #aa121c;
  border-radius: 8px;
  cursor: pointer;
  transition: 200ms all ease-out;
  // border: 1px solid rgba(255, 255, 255, 0.28);

  // opacity: 0.8;
  &:hover {
    opacity: 1;
    background: #bb0e1d;
  }

  @include lato-base();
  font-size: 18px;
  line-height: 134.69%;
  color: #ffffff;
}

.bg-screen {
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 10;
  background: #fff;
  color: #333333;
  border-radius: 8px;

  .text-wrapper {
    max-width: 30vw;
    min-width: 300px;
  }
  p {
    margin: 16px 0;
  }
  .cta-btn {
    margin: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    padding-top: 40vh;
    max-width: 60ch;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.4s ease;
}

button {
  border: none;
  background: #f44336;
  color: #fff;
  padding: 10px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  min-height: 35px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.2s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-default-button {
  float: right;
}

///
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
</style>
