<template>
  <div class="container">
    <bgComponent />
    <div class="cards-container">
      <div class="card title">
        <h1>{{ qTemplate.title }}</h1>
        <span class="line"></span>
        <p class="sub">{{ qTemplate.subtitle }}</p>
        <p class="required">{{ qTemplate.required }}</p>
      </div>

      <div
        v-for="card in qTemplate.cards"
        v-show="currQuestion == card.id"
        :key="card.id"
        class="card"
        :id="`card${card.id}`"
      >
        <p class="question">{{ card.question }}</p>
        <p class="required">*</p>
        <label class="option" v-for="item in card.choices" :key="item.id">
          <input
            v-if="card.type == 'radio'"
            type="radio"
            :value="item.value"
            v-model="card.answer"
            :name="item.value"
          />
          <input
            v-if="card.type == 'checkbox'"
            type="checkbox"
            :value="item.value"
            v-model="card.answer[item.value - 1]"
            :name="item.value"
          />
          {{ item.name }}
        </label>
      </div>
      <p class="errorMsg" v-show="!hideErrorMsg">{{ errorMsg }}</p>
      <div class="end-wrap">
        <p class="progressText">{{ progress }}</p>
        <button @click="buttonClick" class="cta-btn">{{ qTemplate.button }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import bgComponent from "@/components/quiz/bgComponent.vue";
export default {
  methods: {
    buttonClick() {
      // console.log(this.checkAnswer());
      this.hideErrorMsg = true;

      if (
        this.currQuestion < this.qTemplate.cards.length &&
        this.checkAnswer()
      ) {
        // console.log(this.user[this.currQuestion - 1]);
        this.submitQuestionAndAnswer();
        this.currQuestion++;
      } else if (
        this.currQuestion == this.qTemplate.cards.length &&
        this.checkAnswer()
      ) {
        // this.totalScore = this.currScore;
        this.submitQuestionAndAnswer();
        console.log("Total Score: " + this.currScore);
        console.log("Will admit to next");
        this.$emit("btn-click", this.user);
        this.$emit("roundScore", this.currScore);
        this.$emit("roundQuestions", this.currQuestion);
        this.currQuestion = 1;
        this.currScore = 0;
      } else {
        this.hideErrorMsg = false;
      }
    },
    submitQuestionAndAnswer() {
      // @TODO: Hari
      // within here the question should be the string question and answer should be the text of the answers - if multiple, join them with a comma
      // print to console so Karl can check

      const rbs = document.querySelectorAll(
        `#card${this.currQuestion} input[type="radio"], #card${this.currQuestion} input[type="checkbox"]`
      );

      let selectedValue;
      let question = this.qTemplate.cards[this.currQuestion - 1].question;

      let answer = "";

      console.log("Question: " + question); // Print question

      for (const rb of rbs) {
        if (rb.checked) {
          selectedValue = rb.value;
          let answers;

          // // Workaround for true false values
          // if (selectedValue == "true") {
          //   answers = this.qTemplate.cards[this.currQuestion - 1].choices[0];
          // } else if (selectedValue == "false") {
          //   answers = this.qTemplate.cards[this.currQuestion - 1].choices[1];
          // }

          answers = this.qTemplate.cards[this.currQuestion - 1].choices[
            selectedValue - 1
          ];

          if (answers != null || undefined) {
            answer = answer + answers.name + ", ";
            console.log("Answer: " + answer); // Print answer
          }
        }
      }
      console.log({question, answer})
      this.$store.dispatch("Quiz/submit", {question, answer})
    },
    checkAnswer() {
      const rbs = document.querySelectorAll(
        `#card${this.currQuestion} input[type="radio"], #card${this.currQuestion} input[type="checkbox"]`
      );
      let selectedValue;

      // let question = this.qTemplate.cards[this.currQuestion - 1].question;

      // let answer = "";

      // console.log(question); // Print question

      for (const rb of rbs) {
        if (rb.checked) {
          selectedValue = rb.value;
          if (
            this.qTemplate.cards[this.currQuestion - 1].choices[rb.value - 1] !=
            undefined
          ) {
            this.currScore =
              this.currScore +
              this.qTemplate.cards[this.currQuestion - 1].choices[rb.value - 1]
                .score;
          }

          // let answers = this.qTemplate.cards[this.currQuestion - 1].choices[
          //   selectedValue - 1
          // ];

          // // console.log(answers);
          // if (answers != null || undefined) {
          //   answer = answer + answers.name + ", ";
          //   console.log(answer); // Print answer
          // }
          console.log("Score: " + this.currScore);
        }
      }

      if (selectedValue == null || undefined) {
        return false;
      } else {
        return true;
      }
    }
  },
  components: {
    bgComponent
  },
  computed: {
    user() {
      const obj = {};
      for (let i = 0; i < this.qTemplate.cards.length; i++) {
        obj["ans" + (i + 1)] = this.qTemplate.cards[i].answer;
      }

      return obj;
    },
    progress() {
      return `${this.currQuestion} / ${this.qTemplate.cards.length}`;
    }
  },
  data() {
    return {
      hideErrorMsg: true,
      errorMsg: "Please select a valid option",
      currScore: 0,
      currQuestion: 1
    };
  },
  props: {
    qTemplate: {
      type: Object,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/components/quiz/styles/quizStyle.scss";
</style>
